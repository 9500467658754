@mixin kunai-h1 ($color) {
  font-family: 'warnock-pro-display';
  padding: 0;
  margin: 0;
  font-weight: 300;
  color: $color;
}

@mixin kunai-h2 ($color, $backgroundColor) {
  font-family: 'titling-gothic-fb';
  padding: 0;
  margin: 0;
  position: relative;
  font-size: 16px;
  letter-spacing: 12.13px;
  font-weight: 500;
  padding-bottom: 5px;
  color: $color;
  border-bottom: 7px solid $color;

  &:after {
    position: absolute;
    content: "";
    width: 12px;
    height: 7px;
    bottom: -7px;
    right: 0px;
    background: $backgroundColor;
  }
}

@mixin kunai-h3 ($color) {
  font-family: 'titling-gothic-fb';
  padding: 0;
  margin: 0;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: $color;
}

@mixin kunai-regular-text($color) {
  padding: 0;
  margin: 0;
  font-family: 'titling-gothic-fb';
  font-weight: 100;
  font-style: normal;
  font-size: 12.8px;
  color: $color;
}

@mixin kunai-secondary-text($color) {
  padding: 0;
  margin: 0;
  font-family: 'warnock-pro-display';
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  font-size: 12.8px;
  color: $color;
}

@import '../Styles/colors.scss';
@import '../Styles/typography.scss';

.styled-bullet {
  display: flex;
  position: relative;

  img {
    width: 12px;
  }
}
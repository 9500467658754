@import '../Shared/Styles/colors.scss';
@import '../Shared/Styles/typography.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';

.keystor {
    margin-top: 110px;
    margin-bottom: 100px;

    h2 {
        @include kunai-h2($whiteColor, $kunaiBlueColor);
    }

    form {
        padding-top: 50px;
    }

    label {
        @include kunai-regular-text($whiteColor);
        font-size: 14.4px;
        display: block;
        margin-bottom: .5rem;
    }

    input {
        @include kunai-regular-text($kunaiBlueColor);
        padding: .5rem;
        font-size: 13.6px;
        width: 100%;
        display: block;
        border: 1px solid #ccc;
        margin-bottom: 26px;

        @include media-breakpoint-down(md) {
            margin-bottom: 10px;
        }
    }

    .input-feedback {
        color: red;
        margin-top: .25rem;
    }
    
    input.error {
        border-color: red;
    }

    .code-description {
        @include kunai-regular-text($whiteColor);
        font-size: 12.8px;
        padding-top: 35px;
    }

    .code-block {
        border: solid 1px $codeBorderColor;
        margin-top: 10px;
        margin-bottom: 20px;
        
        pre {
            margin-top: 0;
            margin-bottom: 0;
            min-height: 100px;
            max-height: 850px;
            overflow-x: hidden;
        }
    }
}

pre::-webkit-scrollbar {
    width: 1em;
    background: rgb(43, 43, 43);
}
 
pre::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
pre::-webkit-scrollbar-thumb {
    background-color: #484848;
}
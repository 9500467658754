@import '../Shared/Styles/colors.scss';
@import '../Shared/Styles/typography.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';

* {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

*:focus {
  outline: none;
}

a {
  text-decoration: none;
}

.underline-link {
  color: $kunaiBlueColor;
  font-weight: bold;
  border-bottom: 3px solid $kunaiRedColor;
}

.underline-link:hover {
  opacity: .7;
}

img {
  display: block;
}

h1, h2 {
  display: inline-block;
}

.no-padding {
  padding: 0;
}

button {
  min-width: 290px;
  padding: 0px 40px;
  height: 49px;
  @include kunai-regular-text($whiteColor);
  letter-spacing: 4.27px;
  text-transform: uppercase;
  margin-right: 10px;

  @include media-breakpoint-down(md) {
    margin-bottom: 10px;
  }
}

button:hover {
  opacity: .8;
  cursor: pointer;
}

.button-primary {
  border: 2px solid $whiteColor;
  background-color: $whiteColor;
  color: black !important;
}

.button-blue {
  border: 2px solid $kunaiBlueColor;
  background-color: $kunaiBlueColor;
  color: $whiteColor !important;
}

.button-secondary {
  border: 2px solid $whiteColor;
  background-color: transparent;
  color: $whiteColor
}

button:disabled {
  opacity: .5;
  cursor: not-allowed !important;
}

.content-chunk {
  z-index: 1;
  padding: 0px;
  @include media-breakpoint-down(md) {
    padding: 0px 30px;
  }
}

.cubes {
  position: absolute;
  z-index: 0;
  display: block;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.cubes-1 {
  width: 300px;
  right: 140px;
  top: -40px;
}

.cubes-2 {
  width: 340px;
  right: -150px;
  top: 1080px;
}

.cubes-3 {
  width: 280px;
  right: -100px;
  top: 1640px;
}

.cubes-4 {
  width: 150px;
  left: -100px;
  top: 2920px;
}

.cubes-5 {
  width: 150px;
  right: -100px;
  top: 3320px;
}

.cubes-6 {
  width: 300px;
  right: -130px;
  top: 4120px;
  z-index: 2;
}

.cubes-7 {
  width: 135px;
  right: -50px;
  top: 5000px;
}

.cubes-8 {
  width: 135px;
  left: 30px;
  top: 5480px;
  z-index: 2;
}

.cubes-9 {
  width: 135px;
  left: -80px;
  top: 6040px;
}

.cubes-10 {
  width: 316px;
  right: -90px;
  top: 6360px;
}

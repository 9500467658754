@import '../Styles/colors.scss';
@import '../Styles/typography.scss';

.footer {
  background-color: $whiteColor;
  height: 60px;

  &__text {
    @include kunai-regular-text($kunaiBlueColor);
    margin-top: 20px;
    margin-left: 20px;
  }
}
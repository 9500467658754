@import '../Styles/colors.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';

.header {
  background-color: $kunaiBlueColor;
  height: 200px;
  
  &__logo {
    height: 53.9px;
    margin: 40px;
    position: absolute;
    z-index: 1;

    &__img {
      height: 100%;
    }
  }
  
  &__cubes {
    width: 300px;
    right: 140px;
    top: -40px;
    position: absolute;
    z-index: 0;
  }

  &__menu {
    position: absolute;
    right: -22px;
    top: 160px; 
    width: 50px;
    height: 50px;
    border-radius: 100px;
    background: $whiteColor;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

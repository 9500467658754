@import '../Shared/Styles/colors.scss';
@import '../Shared/Styles/typography.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';

.how-it-works {
  margin-top: 100px;
  margin-bottom: 80px;

  &__title {
      @include kunai-h2($kunaiBlueColor, $whiteColor);
      margin-bottom: 80px;
  }
}


.slide__content {
  &__left {
    display: flex;
    p {
      @include kunai-regular-text($kunaiBlueColor)
      text-align: left;
      font-size: 19.2px;
      line-height: 1.75;
      margin-right: 20px;
    }
  }
  
  &__img {
    width: 150px;
    margin-top: 0px;
    @include media-breakpoint-down(lg) {
      margin-top: 30px;
    }
  }
}

@keyframes fadeOut {
  from {
    opacity: 1.0;
  }
  
  to {
    opacity: 0.0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.0;
  }
  
  to {
    opacity: 1.0;
  }
}

.carousel .slide  {
  background: $whiteColor;
  transition: opacity .25s ease-in;
  opacity: 0.0;
}

.selected {
  transition: opacity .25s ease-in;
  opacity: 1.0 !important;
}

.carousel .control-dots {
  position: relative;
  margin-top: -40px;

  @include media-breakpoint-down(lg) {
    margin-top: 60px;
  }
}

.carousel .control-dots .dot.selected {
  background: $kunaiRedColor;
  box-shadow: none;
  height: 18px;
  width: 18px;
}

.carousel .control-dots .dot {
  box-shadow: none;
  opacity: .8;
  background: rgb(150, 150, 150);
  height: 18px;
  width: 18px;
}

.slider-wrapper {
  height: 520px;

  @include media-breakpoint-down(lg) {
    height: auto;
  }
}